
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        



































































































































$bp: l;

.dominopill {
  @include bg-color;

  position: relative;
  overflow: hidden;
  background-color: $c-white-dark;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 90%;
  }
}

.dominopill__speedlines {
  top: inherit;
  right: $spacing;
  height: 130%;

  .col-big & {
    top: 12%;
    bottom: 0;
    height: 113%;
  }

  @include mq($until: l) {
    display: none;
  }
}

.dominopill-inner {
  position: relative;
  /* stylelint-disable-next-line declaration-no-important */
  padding-top: 5rem !important;
  /* stylelint-disable-next-line declaration-no-important */
  padding-bottom: 5rem !important;
}

.dominopill-inner,
.dominopill__title,
.dominopill__cards {
  position: relative;
  z-index: 1;
}

.dominopill__title {
  margin: 0 0 3rem;

  @include mq($bp) {
    margin: 12rem 0 3rem;
  }
}

.dominopill__cards {
  position: relative;
  overflow: hidden;
  background-color: $white;
  border: 1px solid $c-light-gray;
  border-radius: 20px;
  box-shadow: 0 4px 10px rgba($black, 0.05);

  .col-big & {
    @include mq(l) {
      background-color: transparent;
      border: none;
    }
  }

  &.dominopill__cards-4 {
    &::before {
      @include mq(l) {
        position: absolute;
        left: 50%;
        content: '';
        display: block;
        width: 1px;
        height: 100%;
        background-color: $c-light-gray;
        transform: translateX(-50%);
      }
    }

    .dominopill__card {
      &:nth-child(1),
      &:nth-child(2) {
        border-bottom: 1px solid $c-light-gray;
      }
    }
  }

  @include mq($bp) {
    display: flex;
    flex-wrap: wrap;
    width: 70%;
  }

  @include mq(xxl) {
    width: 60%;
  }
}

.dominopill__card {
  flex-grow: 1;
  padding: 2rem 2rem 3rem;

  .col-big & {
    background-color: #fff;
  }

  .dominopill__cards-3 &,
  .dominopill__cards-4 & {
    @include mq(m) {
      background-color: #fff;
    }

    @include mq($until: l) {
      &:not(:first-child) {
        border-top: 1px solid $c-light-gray;
      }
    }

    @include mq(l) {
      max-width: 50%;
      padding: 4.6rem 5rem 5rem;
    }
  }

  .dominopill__cards-3 & {
    &:nth-child(1) {
      border-right: 1px solid $c-light-gray;
      border-bottom: 1px solid $c-light-gray;
      border-top-left-radius: 20px;
    }

    &:nth-child(2) {
      border-bottom: 1px solid $c-light-gray;

      @include mq(l) {
        border-radius: 0 20px 20px 0;
      }
    }

    &:nth-child(3) {
      border-radius: 0 0 20px 20px;
    }
  }
}

.dominopill__card__icon {
  max-height: 4rem;
  fill: $c-pink-medium;

  .col-big & {
    margin-bottom: 1rem;
  }
}

.dominopill__card__title,
.dominopill__card__text,
.dominopill__card__icon {
  margin: 0 0 1.5rem;

  @include mq($bp) {
    margin: 0 0 2rem;
  }
}

.dominopill__card__title {
  @include fluid-type(20, 24, xl);
}

.dominopill__card__text {
  @include fluid-type(15, 18);

  line-height: 1.6;
}

.btn + .link {
  margin-left: $spacing;
}


          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        
































.hero-light {
  padding-top: $spacing * 4;

  @include mq('m') {
    padding-top: $spacing * 8;
  }
}

.hero-light__content {
  margin-top: $spacing * 2;

  @include mq('m') {
    margin-top: $spacing * 5;
    text-align: center;
  }
}

.hero-light__content__text {
  margin: 0 auto;

  @include mq('m') {
    max-width: 70%;
  }
}

.hero-light__content__subtitle {
  margin-bottom: $spacing * 1.5;
}
